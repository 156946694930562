import luge from '@waaark/luge';

// Go to top button
document.querySelector("#top-btn").addEventListener("click", toTop);

function toTop (){
document.querySelector("#top").scrollIntoView({behavior: "smooth"});
};


/////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

// % Progress bar

let progressSection = document.querySelector('.progress-section');
let progressBar = document.querySelector('.progress-bar');
let progressNum = document.querySelector('.progress-num');

let x, y;

function updateProgressBar() {
 progressBar.style.height = `${getScrollPercentage()}%`;
 progressNum.innerText = `${Math.ceil(getScrollPercentage())}%`;
 requestAnimationFrame(updateProgressBar);
}

function getScrollPercentage(){
    console.log(window.scrollY);
    return ((window.scrollY) / (document.body.scrollHeight - window.innerHeight) * 100 )
    
}

updateProgressBar()




// Displaying scroll or Back to Top button

let topBtn = document.querySelector("#top-btn");
let scrollBtn = document.querySelector("#scroll-btn");

window.addEventListener('scroll', function() {
   
    let scroll = window.scrollY;
    if (scroll > 0) {
        
        topBtn.classList.add('visible');
        topBtn.classList.remove('hidden');
        scrollBtn.classList.add('hidden');
        scrollBtn.classList.remove('visible');


    } else {
   
        topBtn.classList.add('hidden');
        topBtn.classList.remove('visible');
        scrollBtn.classList.add('visible');
        scrollBtn.classList.remove('hidden');
    }
}); 

/////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////